import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import citiesList from "@/config/citiesList.json"

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.provider-register',
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false,
        save: {
          text: 'action.register',
          //@ts-ignore
          create: () => !vm.isRegistered
        },
        // save: () => {
        //   //@ts-ignore
        //   return !vm.isRegistered
        // },
      },
      blocks: [
        {
          title: 'form_block.base',
          disabled: (formInfo) => {
            //@ts-ignore
            return vm.isRegistered
          },
          data: {
            name: {
              required: true,
              label: 'provider.data.name',
              type: 'liff-text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_name: {
              label: 'provider.data.member_name',
              type: 'liff-text',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_phone: {
              label: 'data.member_phone',
              type: 'liff-text',
              required: true,
              maxlength: 10,
              phone: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            email: {
              label: 'data.email',
              type: 'liff-text',
              required: true,
              email: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            city: {
              label: 'provider.data.city',
              type: 'liff-selection',
              clearable: true,
              options: () => {
                return citiesList.map(item => ({
                  text: item.city,
                  value: item.city
                }))
              },
              grid: {
                cols: 6,
              },
              itemClass: "mb-2",
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            district: {
              label: 'provider.data.district',
              type: 'liff-selection',
              clearable: true,
              options: (formInfo) => {
                if(!formInfo.formData) return []
                const city = formInfo.formData.city
                if(!city) return []

                const targetCity = citiesList.find(item => {
                  return item.city == city
                })

                if(!targetCity) return []

                return targetCity.districts.map(district => ({
                  text: district.name,
                  value: district.name
                }))
              },
              grid: {
                cols: 6,
              },
              itemClass: "mb-2",
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            address: {
              label: 'data.ship-address',
              type: 'liff-text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },
      ],
    }
  }

}

export default new formConfig()
